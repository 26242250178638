import React from "react"
import { Card } from "antd"
import styled from "styled-components"
import { MehFilled } from "@ant-design/icons"

const StyledCard = styled(Card)`
  justify-content: center;
  text-align: center;
`

const StyledHeading = styled.h1`
  font-size: 50px;
  display: flex;
  max-width: 200px;
  justify-content: center;
  margin: auto;
`

const StyledIcon = styled(MehFilled)`
  margin-top: auto;
  margin-bottom: auto;
`

const StyledDescription = styled.p`
  font-size: 20px;
`

const NotFound404 = () => {
  return (
    <>
      <StyledCard>
        <StyledHeading>
          <StyledIcon />
          <strong>ops!</strong>
        </StyledHeading>
        <StyledDescription>Page not found.</StyledDescription>
      </StyledCard>
    </>
  )
}

export default NotFound404
